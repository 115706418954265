import React from "react"

import Layout from "../components/layout"
import Contact from "../components/contact"
import Hours from "../components/hours"

const OrderPage = () => {
    return (
        <Layout>
            <div class="columns">
                <Contact />
                <Hours />
            </div>
        </Layout>
    )
}

export default OrderPage
